import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import axios from 'axios';
import ChatService from 'myt-chat/src/external/ChatService';
import ChannelUtils from 'myt-chat/src/chat/utils/ChannelUtils';
import UserRole from 'myt-chat/src/chat/enums/UserRole';
import HtmlReactParser from 'html-react-parser';
import {
  getNumWithOrdinal,
  setCookie,
  getCookie,
  removeCookie,
  isDevMode,
  copyTextToClipboardinHttp
} from '../../../util_functions';
import TestYourSetup from '../../util_components/test_your_setup';
import StudentProfile from '../user_sch_session/StudentProfile';
import CancelAlert from '../../util_components/cancel_alert';
import CustomLoading from '../../util_components/custom_loading';
import PopUpText from '../../util_components/custom_single_pop_up/PopUpText';
import * as actions from '../../../redux/action_creators';
import './dashboard_funcs.css';

import './new_dash.css';
import './ps_participant_pop.css';
import phone_white from '../../../assets/images/phone_white.svg';
import Questions from './helpful_questions';
import urls from '../../../urls';
import { capitalizeFirstLetter } from '../../../formatString';

import DashboardScheduleClassPopup from '../../../new_teacher_design/components/popups/DashboardScheduleClassPopup';
import ScheduleConfirmation from '../../../new_teacher_design/components/popups/ScheduleConfirmation';
import SubscritionPopup from '../../../new_teacher_design/components/popups/CreditPopup';
import {
  CloseButtonIcon,
  BlueRightArrow,
  PinkRightArrow,
  RefreshIcon,
  RegisteredIcon
} from '../../../../svg';
import CustomModal from '../../util_components/custom_modal';
import CustomButton from '../../util_components/custom_button';
import ClickDetect from '../../../new_teacher_design/components/FixedComponent/ClickDetecte';
import PainLevelPopup from '../../../new_teacher_design/BackPainTherapy/PainLevelPopup';
import GroupButton from '../../GroupSessionButton';
import { withRouter } from '../../../utils/router';
import ChangeSessionDateAndTime from '../pop_up/ChangeSessionDateAndTime';
import CancelSessionPopup from '../pop_up/CancelSession';
import JoinMeetingCheckList from './JoinMeetingCheckList';
import ConciergeNotesPopup from './ConciergeNotesPopup';
import { post_api } from '../../../new_teacher_design/utils/config';

const getDifferenceFromNow = (epochTime) => epochTime - new Date().getTime();

class SingleRenderedSession extends Component {
  state = {
    showHelpfulQuestionsPopup: false,
    consultCallActive: false,
    test_setup_open: false,
    more_modal_open: false,
    open_student_profile: false,
    cancel_alert_open: false,
    is_replacement_open: false,
    countdown: true,
    show_pick_date: false,
    showBannerChangeTime: false,
    open_join_popup: false,
    loading: false,
    hours: 0,
    minutes: 0,
    days: 0,
    participantNavStep: 'trial',
    parti_options_open: false,
    participant_opt_id: '',
    show_ban_popup: false,
    banParticipantData: {},
    banParticipant: false,
    banloading: false,
    checkedBanReasons: [],
    selectAlert: false,
    isAllReasonSelected: false,
    show_all_non_joined: false,
    ScheduleClass: false,
    cancelClassPopup: false,
    subscritiopnPopup: false,
    sessionScheduledSuccess: false,
    channelUUID: '',
    channelType: '',
    show_cancel_confirm: false,
    moreOptions: false,
    showPainLevelPopup: false,
    channelUnread: 0,
    remainingTime: '',
    dropdownPosition: 'below',
    isJoinMeetingPopupOpen: false,
    isConciergeNotesPopupOpen: false,
    studentDetailsdata: []
  };

  customProfileRef = React.createRef();

  iconRef = React.createRef();

  moreOptionRef = React.createRef();

  componentDidMount() {
    this.interval = setInterval(this.updateRemainingTime, 1000);

    document.addEventListener('mousedown', this.handleMouseClick, false);

    this.intervalId = setInterval(() => {
      const diff = getDifferenceFromNow(this.props.item.epoch_end_time + 2000); // adding 2000ms as a buffer before reload
      if (diff <= 0) {
        clearInterval(this.intervalId);
        window.location.reload();
      }
    }, 1000);

    if (this.props.cancel_session_status === 'success') {
      this.setState({ show_cancel_confirm: true });
    }

    const channelID = this.findChannel();
    fetch(urls.chat_unread.replace(':CHANNEL_ID', channelID), {
      headers: {
        Authorization: `Bearer ${getCookie('teacher_token_1')}`
      }
    })
      .then((x) => x.json())
      .then((response) => {
        if (response.unread) {
          this.setState({
            channelUnread: response.unread
          });
        }
      })
      .catch((e) => console.log(e));
    this.setDefaultTab();
  }

  countDownReplacement() {
    const wt = moment(this.props.item.waiting_time_limit_for_replacement);
    const currentTime = moment();
    const diff = wt.diff(currentTime, 'minutes');
    let hours = Math.floor(diff / 60);
    const days = Math.floor(hours / 24);
    if (days > 0) {
      hours %= 24;
    }

    const minutes = Math.floor(diff % 60);
    this.setState({
      hours,
      minutes,
      days
    });

    this.replacementInterval = setInterval(() => {
      const wt = moment(this.props.item.waiting_time_limit_for_replacement);
      const currentTime = moment();
      const diff = wt.diff(currentTime, 'minutes');
      let hours = Math.floor(diff / 60);
      const days = Math.floor(hours / 24);
      if (days > 0) {
        hours %= 24;
      }

      const minutes = Math.floor(diff % 60);
      this.setState({
        hours,
        minutes,
        days
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    document.removeEventListener('mousedown', this.handleMouseClick, false);
    clearInterval(this.intervalId);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.zoom_signature_status === 'loading' &&
      this.props.zoom_signature_status === 'success'
    ) {
      this.setState({ open_join_popup: true });
    }

    if (
      prevProps.get_session_participants_status === 'loading' &&
      this.props.get_session_participants_status === 'success'
    ) {
      if (
        prevProps.get_session_participants_response !== this.props.get_session_participants_response
      ) {
        this.setDefaultTab();
      }
      this.setState({
        loading: false,
        show_participant_list: true
      });
    }

    if (
      prevProps.get_session_participants_status === 'loading' &&
      this.props.get_session_participants_status === 'fail'
    ) {
      this.setState({
        loading: false
      });
    }

    if (
      prevProps.create_roadmap_status === 'loading' &&
      (this.props.create_roadmap_status === 'success' ||
        this.props.create_roadmap_status === 'fail') &&
      this.state.loading
    ) {
      this.setState({
        loading: false
      });
    }

    if (
      prevProps.request_replacement_status === 'loading' &&
      this.props.request_replacement_status === 'success'
    ) {
      this.setState(
        {
          loading: false
        },
        () => {
          this.countDownReplacement();
        }
      );
    }

    if (prevProps.consult_call_active !== this.props.consult_call_active) {
      this.setState({
        consultCallActive: this.props.consult_call_active,
        showHelpfulQuestionsPopup: false
      });
    }

    if (
      prevProps.impersonate_myt_user_teacher_consultancy_response_status === 'loading' &&
      this.props.impersonate_myt_user_teacher_consultancy_response_status === 'success'
    ) {
      setCookie(
        'impersonationFromTeacherToken',
        this.props.impersonate_myt_user_teacher_consultancy_response.token
      );
      removeCookie('student_token');

      const url = urls.student_impersonation_url;
      window.open(
        `${url}&consult_id=${this.props.item.consult_id}`,
        '',
        'directories=no,toolbar=no,location=0,status=no,menubar=no, statusbar=0, scrollbars=no,resizable=no'
      );
    }

    if (
      prevProps.impersonate_myt_user_teacher_consultancy_response_status === 'loading' &&
      this.props.impersonate_myt_user_teacher_consultancy_response_status === 'fail'
    ) {
      alert('Something went wrong');
    }

    if (
      prevProps.cancel_session_status === 'loading' &&
      this.props.cancel_session_status === 'success'
    ) {
      this.setState({ show_cancel_confirm: true });
    }
  }

  render_confirm_cancel = () => (
    <CustomModal
      show={this.state.show_cancel_confirm}
      close={() => this.close_confirm_cancel_modal()}
    >
      <div className="text-center cancel-popup-body">This session has been canceled </div>
      <div className="cancel-popup-button flex  justify-center">
        <CustomButton label="Ok" onClick={() => this.close_confirm_cancel_modal()} />
      </div>
    </CustomModal>
  );

  close_confirm_cancel_modal = () => {
    this.setState({ show_cancel_confirm: false });
    this.props.set_home_variable('cancel_session_status', 'none');
  };

  impersonateStudent = () => {
    const { student_uuid } = this.props.item;
    this.props.impersonate_myt_user_teacher_consultancy({
      user_uuid: student_uuid,
      user_type: 'STUDENT'
    });
  };

  handleMouseClick = (e) => {
    if (this.state.more_modal_open) {
      if (!this.moreOptionRef.current.contains(e.target)) {
        this.setState({ more_modal_open: false });
      }
    }
    if (
      this.state.parti_options_open &&
      this.customProfileRef.current &&
      !this.customProfileRef.current.contains(e.target)
    ) {
      const iconRef = this.iconRef[this.state.participant_opt_id];
      if (iconRef && !iconRef.contains(e.target)) {
        this.setState({ parti_options_open: false });
      }
    }
  };

  cancel_zoom_session = () => {
    this.setState({ open_join_popup: false });
  };

  open_now_click = () => {
    this.props.join_session({
      session_uuid: this.state.session_uuid,
      device: 'web'
    });
    this.props.session_log_start({ session_uuid: this.state.session_uuid });
    this.props.set_zoom_variable('zoom_signature', '');
    this.props.set_zoom_variable('zoom_signature_status', 'none');
    this.setState({ open_join_popup: false });
  };

  render_safari_popup = () => (
    <PopUpText
      closePopup={this.cancel_zoom_session}
      bodytext="Click on the button below to join your session"
      btnfooter={
        <div className="btn-cont">
          <a
            className="btn btn-primary cursor-pointer w-full text-17px h-49px mt-40px"
            href={this.props.join_url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={this.open_now_click}
          >
            Confirm
          </a>
        </div>
      }
    />
  );

  openProfilePage = (studentSlug) => {
    window.open(`/student-profile/${studentSlug}`);
  };

  openAddRoadmap = (item) => this.props.router.navigate(`/add-road-map/${item.student_slug}`);

  open_student_profile = () => {
    this.openProfilePage(this.props.item.student_slug);
  };

  open_student_add_roadmap = () => {
    this.props.set_home_variable('currentRoadMapSession', this.props.item);
    this.props.router.navigate(`/add-road-map/${this.props.item.student_slug}`, {
      state: { item: this.props.item }
    });
  };

  close_student_profile = () => this.setState({ open_student_profile: false });

  showBannerChangeTime = () => {
    this.setState({ showBannerChangeTime: true }, () => {
      setTimeout(() => {
        this.setState({ showBannerChangeTime: false });
      }, 3000);
    });
  };

  close_test_setup = () => this.setState({ test_setup_open: false });

  open_test_setup = () => {
    this.setState({ test_setup_open: true });
  };

  toggle_modal = () => this.setState({ more_modal_open: !this.state.more_modal_open });

  withdraw_session_replacement = (e) => {
    this.props.withdrawReplacementRequest({
      sessionUUID: this.props.item.session_uuid
    });
    this.setState({
      session_uuid: e.currentTarget.dataset.div_session_uuid
    });
  };

  openMeeting = (e) => {
    if (this.props.item.call_provider === 'livekit') {
      this.triggerLiveKitJoin(e);
    } else {
      this.set_zoom_session(e);
    }
    this.setState({
      isJoinMeetingPopupOpen: false,
      isConciergeNotesPopupOpen: false
    });
  };

  set_zoom_session = () => {
    const { session_uuid } = this.props.item;
    this.setState({
      session_uuid
    });
    this.props.set_home_variable('selected_dashboard_session_uuid', session_uuid);
    this.props.get_zoom_token({
      role: 1,
      session_uuid
    });
  };

  getStudentDetails = async () => {
    const { student_slug } = this.props.item || {};
    const url = urls.student_details_slug;
    const payload = {
      student_slug,
      session_uuid: this.props.item.session_uuid
    };
    try {
      const res = await post_api(url, payload, true);
      return res?.data?.details;
    } catch (err) {
      console.log(err, 'error');
    }
  };

  handleJoinMeetingButton = async (e) => {
    const studentDetails = await this.getStudentDetails();
    const { session_type, session_attend_text, session_attending } = this.props.item || {};
    const isOneOnOne = session_type === 'YOGA';
    const hasAttendText = !!session_attend_text;
    let sessionRecaps = '';
    let teacherNotes = '';
    if (studentDetails?.first_one_on_one_session_notes?.length !== 0) {
      sessionRecaps = studentDetails?.first_one_on_one_session_notes[0]?.session_recap;
      teacherNotes = studentDetails?.first_one_on_one_session_notes[0]?.teacher_notes;
    }

    const gettingToKnowStudentNotes = studentDetails?.student_additional_info;

    if (hasAttendText && isOneOnOne) {
      if (session_attending === 1) {
        this.setState({ isJoinMeetingPopupOpen: true });
      } else if (
        session_attending === 2 &&
        (gettingToKnowStudentNotes !== '' || sessionRecaps !== '' || teacherNotes !== '')
      ) {
        this.setState({
          isConciergeNotesPopupOpen: true,
          studentDetailsdata: studentDetails
        });
      } else if (
        gettingToKnowStudentNotes !== '' ||
        (studentDetails.teacher_session_notes !== '' &&
          studentDetails.teacher_session_notes !== undefined)
      ) {
        this.setState({
          isConciergeNotesPopupOpen: true,
          studentDetailsdata: studentDetails
        });
      } else {
        this.openMeeting(e);
      }
    } else {
      this.openMeeting(e);
    }
  };

  stop_propagation = (e) => {
    e.stopPropagation();
  };

  change_time = (sessionUuid) => {
    this.props.get_session_update_slots({
      session_uuid: sessionUuid,
      is_12_hours: 1
    });
    this.setState({
      show_pick_date: true,
      moreOptions: false
    });
  };

  cancel_session = (cancel_reason, other_details) => {
    const { session_uuid } = this.props.item;
    const payload = {
      session_uuid,
      cancel_reason,
      other_details
    };
    this.props.cancel_session(payload);
    this.setState({ cancel_alert_open: false, more_modal_open: false, moreOptions: false });
  };

  cancel_open = () => this.setState({ cancel_alert_open: true });

  cancel_close = () => this.setState({ cancel_alert_open: false });

  toggle_replacement = () =>
    this.setState({ is_replacement_open: !this.state.is_replacement_open, moreOptions: false });

  render_cancel_alert = () =>
    this.state.cancel_alert_open ? (
      <CancelSessionPopup
        bodyText="Please select reason for cancellation"
        cancelFunc={this.cancel_close}
        session_uuid={this.props.item.session_uuid}
        is_repeat={this.props.item.is_recurring}
      />
    ) : null;

  render_request_replacement = () => {
    const { allow_teacher_replacement } = this.props.item;
    const body_text =
      this.props.item.session_type === 'YOGA' && allow_teacher_replacement === 0
        ? `Are you sure you want to go ahead?<br/> <div className='cancel-popup-warn'>*This session will be cancelled because student does not want a replacement Teacher.</div>`
        : 'Are you sure you want to request replacement?';
    let reasons = [];
    try {
      reasons = [...this.props.config_values.teacher_cancel_reasons];
    } catch {}
    if (this.state.is_replacement_open) {
      return (
        <CancelAlert
          bodyText={body_text}
          cancelFunc={this.toggle_replacement}
          confirmFunc={this.request_replacement}
          reasons={reasons}
        />
      );
    }
  };

  request_replacement = (cancel_reason, other_details) => {
    this.setState({
      loading: true
    });
    const { session_uuid } = this.props.item;
    const payload = {
      session_uuid,
      reason_for_replacement: cancel_reason,
      details_for_replacement: other_details
    };
    this.props.request_replacement(payload);
    this.setState({
      is_replacement_open: false,
      more_modal_open: false,
      moreOptions: false
    });
  };

  openScheduleClassPopup = () => {
    this.setState({
      more_modal_open: false,
      ScheduleClass: true,
      moreOptions: false
    });
  };

  cancel_pick_date = () => {
    this.setState({
      show_pick_date: false
    });
  };

  create_roadmap = () => {
    this.setState({ loading: true }, () =>
      this.props.create_roadmap({ session_uuid: this.props.item.session_uuid })
    );
  };

  closeViewParticiPop = () => {
    if (this.state.banParticipant) {
      window.location.reload();
    }
    this.setState({
      show_participant_list: false,
      participantNavStep: 'trial',
      parti_options_open: false,
      participant_opt_id: '',
      show_ban_popup: false,
      banParticipant: false,
      banParticipantData: {},
      checkedBanReasons: [],
      show_all_non_joined: false,
      selected_session_uuid: ''
    });
  };

  show_participant_list = (session_uuid) => {
    this.setState(
      {
        selected_session_uuid: session_uuid
      },
      () => this.props.get_session_participants({ session_uuid })
    );
  };

  handleParticipantNav = (e) => {
    this.setState({
      participantNavStep: e.target.name
    });
  };

  setDefaultTab = () => {
    const { get_session_participants_response } = this.props;
    const total_participants =
      get_session_participants_response?.session_details?.total_participants || [];

    // Check if trial exists, otherwise set to member
    const trialExists = total_participants.some(
      (participant) => participant.label.toLowerCase() === 'trial'
    );
    const memberExists = total_participants.some(
      (participant) => participant.label.toLowerCase() === 'member'
    );

    if (trialExists) {
      this.setState({ participantNavStep: 'trial' });
    } else if (memberExists) {
      this.setState({ participantNavStep: 'member' });
    } else {
      // Default to the first available tab if neither trial nor member exists
      const firstAvailableTab = total_participants[0]?.label.toLowerCase() || '';
      this.setState({ participantNavStep: firstAvailableTab });
    }
  };

  render_tab_data = () => {
    switch (this.state.participantNavStep) {
      case 'trial':
        return this.show_participants_tab_data('trial');
      case 'member':
        return this.show_participants_tab_data('member');
      default:
        return this.show_participants_tab_data('trial');
    }
  };

  handleOptionsChange = (e) => {
    const { id } = e.currentTarget;
    const iconRect = this.iconRef[id].getBoundingClientRect();
    const dropdownHeight = 150; // Adjust this value to the actual height of your dropdown menu

    // Check if there is enough space below the icon for the dropdown
    const isSpaceBelow = window.innerHeight - iconRect.bottom > dropdownHeight;

    this.setState(
      (prevState) => ({
        parti_options_open: prevState.participant_opt_id !== id || !prevState.parti_options_open,
        participant_opt_id: id,
        dropdownPosition: isSpaceBelow ? 'below' : 'above'
      }),
      () => {
        // Scroll the dropdown into view if needed
        if (this.customProfileRef.current) {
          this.customProfileRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
        }
      }
    );
  };

  handleBanParticipant = () => {
    this.setState({
      show_ban_popup: !this.state.show_ban_popup,
      parti_options_open: false
    });
  };

  handleBanReason = (event) => {
    let newArr = [...this.state.checkedBanReasons, event.target.value];
    if (this.state.checkedBanReasons.includes(event.target.value)) {
      newArr = newArr.filter((reason) => reason !== event.target.value);
    }
    this.setState({
      checkedBanReasons: newArr,
      selectAlert: false
    });
    if (this.state.checkedBanReasons.length !== this.props.config_values?.student_ban_reasons) {
      this.setState({
        isAllReasonSelected: false
      });
    }
  };

  handleAllBanReason = (e) => {
    if (e.target.checked) {
      const newArray = this.props.config_values?.student_ban_reasons;
      this.setState({
        checkedBanReasons: newArray,
        isAllReasonSelected: true
      });
    } else if (
      this.state.checkedBanReasons.length !== this.props.config_values?.student_ban_reasons
    ) {
      this.setState({
        isAllReasonSelected: false
      });
    } else {
      this.setState({
        checkedBanReasons: this.state.checkedBanReasons,
        isAllReasonSelected: false
      });
    }
  };

  render_options = (participants) => {
    const { dropdownPosition, parti_options_open, participant_opt_id } = this.state;
    const isOpen = parti_options_open && participant_opt_id === participants.student_uuid;
    if (!isOpen) return null;
    const dropdownStyle = dropdownPosition === 'below' ? { top: '100%' } : { bottom: '100%' };

    return (
      <div
        className="absolute right-0 top-55px py-25px w-max bg-white flex flex-col justify-center border border-gray-200 shadow-mytboxshadow rounded-lg cursor-pointer px-30px z-50 ml-1 gap-12px"
        style={{ ...dropdownStyle, right: 10 }}
        ref={this.customProfileRef}
        onClick={this.stop_propagation}
      >
        <div
          className="class-btn-size text-black hover:text-red-600 font-inter font-semibold text-15.5px leading-19px"
          onClick={() => this.openProfilePage(participants.student_slug)}
        >
          View profile
        </div>
        <div
          className="class-btn-size text-black hover:text-red-600 font-inter font-semibold text-15.5px leading-19px"
          onClick={() => {
            this.closeViewParticiPop();
            ChatService.openP2PChat(participants.student_uuid);
          }}
        >
          Chat
        </div>
        <div
          className="class-btn-size text-red-600 font-inter font-semibold text-15.5px leading-19px"
          onClick={() => {
            this.handleBanParticipant();
            this.setState({
              banParticipantData: participants
            });
          }}
        >
          Ban {participants.student_name}
        </div>
      </div>
    );
  };

  show_participants_tab_data = (tab_type) => {
    const participants_key =
      this.props.get_session_participants_response.session_details?.show_participants_by_join === 0
        ? 'participants'
        : 'joined_participants';
    return (
      <ul className="ps_paricipi_ul">
        {this.props.get_session_participants_response.session_details.total_participants.length !==
          0 &&
          this.props.get_session_participants_response.session_details.total_participants.map(
            (data, index) => (
              <div key={index}>
                {data?.label.toLowerCase() === tab_type.toLowerCase() ? (
                  data?.[participants_key].length !== 0 ? (
                    data[participants_key]
                      .sort((a, b) => {
                        if (a.student_name.toLowerCase() < b.student_name.toLowerCase()) return -1;
                        if (a.student_name.toLowerCase() > b.student_name.toLowerCase()) return 1;
                        return 0;
                      })
                      .sort(
                        (a, b) => b.is_first_session_with_teacher - a.is_first_session_with_teacher
                      )
                      .sort((a, b) => b.is_first_session - a.is_first_session)
                      .map((participant, index2) => (
                        <li
                          className={`sm:px-30px zm:px-30px py-12px flex justify-between ${
                            participant?.is_banned === 1 && 'bg-red-100'
                          }`}
                          key={index2}
                        >
                          <div className="flex">
                            {/* Avatar */}
                            <div
                              onClick={() => this.openProfilePage(participant.student_slug)}
                              className={`h-32px w-32px rounded-full bg-cover cursor-pointer ${
                                !participant.student_profile_photo ? 'no_image' : ''
                              } ${participant?.is_banned === 1 && 'filter blur-sm'}`}
                              style={{
                                backgroundImage: `url(${participant.student_profile_photo})`
                              }}
                            >
                              {!participant.student_profile_photo &&
                              participant.student_name &&
                              participant.student_name.length > 0
                                ? participant.student_name[0]
                                : ''}
                            </div>

                            {/* participant data */}
                            <div className="flex flex-col">
                              <span
                                onClick={() => this.openProfilePage(participant.student_slug)}
                                className={`ml-10px text-16px font-semibold font-inter cursor-pointer ${
                                  participant?.is_banned === 1 && 'filter blur-sm'
                                }`}
                              >
                                {participant.student_name}
                                {!!participant.is_first_session_with_teacher && (
                                  <span className="first_session_tag">
                                    {' '}
                                    (1st session with teacher)
                                  </span>
                                )}
                              </span>
                              <span className="ml-10px flex sm:flex-row zm:flex-col">
                                <div className="flex items-center">
                                  <p className="text-mytorange-default text-12px mr-2 font-normal">
                                    {' '}
                                    {getNumWithOrdinal(
                                      participant?.class_count_with_this_teacher
                                    )}{' '}
                                    {'  '}class
                                  </p>
                                  <p className="text-12px font-normal">
                                    ({getNumWithOrdinal(participant?.class_count_overall)} overall)
                                  </p>
                                </div>
                                <div className="flex items-center">
                                  <div className="rounded-full h-1.5 w-1.5 bg-gray-300 mr-2 sm:ml-4" />{' '}
                                  <p className="text-12px font-normal">
                                    {participant?.student_type}
                                  </p>
                                </div>
                              </span>
                            </div>
                          </div>
                          {/* participant more options */}
                          {/* btn for more options or banned users START */}

                          {participant?.is_banned === 1 ? (
                            <button
                              className="disabled:opacity-50 disabled-btn-danger absolute right-4 text-xs rounded p-2 font-normal cursor-not-allowed flex"
                              disabled
                            >
                              <img
                                src="https://images.myyogateacher.com/ban.png"
                                alt="banned"
                                className="w-4 h-4 inline mr-2"
                              />
                              Banned
                            </button>
                          ) : (
                            <>
                              {this.props.item.session_type === 'GROUP_SESSION' ||
                              this.props.item.session_type === 'GROUP_SESSION_STUDENT' ? (
                                <div
                                  id={participant.student_uuid}
                                  onClick={this.handleOptionsChange}
                                >
                                  <img
                                    alt="participant options"
                                    src="https://images.myyogateacher.com/double_arrow.png"
                                    className="absolute right-6 top-6 cursor-pointer"
                                    ref={(ref) => {
                                      this.iconRef = {
                                        ...this.iconRef,
                                        [participant.student_uuid]: ref
                                      };
                                    }}
                                  />
                                  {this.render_options(participant)}
                                </div>
                              ) : (
                                <button
                                  className="zm:w-80px h-46px btn-white-default p-0 lg:w-58px"
                                  onClick={() => {
                                    this.closeViewParticiPop();
                                    ChatService.openP2PChat(participant.student_uuid);
                                  }}
                                >
                                  <img
                                    className=""
                                    src="https://images.myyogateacher.com/web_images/header/v1/chat-icon.svg"
                                    alt="icon"
                                  />
                                </button>
                              )}
                            </>
                          )}
                          {/* btn for more options or banned users ENDS */}
                        </li>
                      ))
                  ) : (
                    <div className="py-4 text-center">No data found</div>
                  )
                ) : null}
                {data?.label.toLowerCase() === tab_type.toLowerCase() &&
                  this.props.get_session_participants_response.session_details
                    ?.show_participants_by_join === 1 &&
                  this.render_not_joined_participants(data, tab_type)}
              </div>
            )
          )}
      </ul>
    );
  };

  render_not_joined_participants = (data, tab_type) => (
    <>
      <div className="bg-gray-100 zm:px-30px md:px-40px py-3.5 flex justify-between">
        <p className="text-black zm:text-12.5px zm:leading-15px md:leading-18px md:text-15px font-medium font-inter opacity-40">
          {data?.not_joined_participants_count} more registered but haven't joined
        </p>
        <span
          className="md:text-15px zm:text-12.5px font-medium font-inter text-mytLitePurpleTextDefaultV1 cursor-pointer"
          onClick={() => {
            this.setState({
              show_all_non_joined: true
            });
          }}
        >
          Show all
        </span>
      </div>
      {this.state.show_all_non_joined && this.show_nonjoined_participants(data)}
    </>
  );

  show_nonjoined_participants = (data) => (
    <ul className="ps_paricipi_ul">
      {data?.not_joined_participants.length !== 0 ? (
        data.not_joined_participants
          .sort((a, b) => {
            if (a.student_name.toLowerCase() < b.student_name.toLowerCase()) return -1;
            if (a.student_name.toLowerCase() > b.student_name.toLowerCase()) return 1;
            return 0;
          })
          .sort((a, b) => b.is_first_session_with_teacher - a.is_first_session_with_teacher)
          .sort((a, b) => b.is_first_session - a.is_first_session)
          .map((participant, index) => (
            <li className={`px-8 py-4 ${participant?.is_banned === 1 && 'bg-red-100'}`} key={index}>
              <div
                onClick={() => this.openProfilePage(participant.student_slug)}
                className={`ps_partici_pic ${
                  !participant.student_profile_photo ? 'no_image' : ''
                } ${participant?.is_banned === 1 && 'filter blur-sm'}`}
                style={{
                  backgroundImage: `url(${participant.student_profile_photo})`
                }}
              >
                {!participant.student_profile_photo &&
                participant.student_name &&
                participant.student_name.length > 0
                  ? participant.student_name[0]
                  : ''}
              </div>

              {/* participant data */}
              <div className="flex flex-col">
                <span
                  onClick={() => this.openProfilePage(participant.student_slug)}
                  className={`ps_partici_name ${participant?.is_banned === 1 && 'filter blur-sm'}`}
                >
                  {participant.student_name}
                  {!!participant.is_first_session_with_teacher && (
                    <span className="first_session_tag"> (1st session with teacher)</span>
                  )}
                </span>
                <span className="px-3 flex items-center font-inter">
                  <p className="text-mytorange-default mr-2 font-normal">
                    {' '}
                    {getNumWithOrdinal(participant?.class_count_with_this_teacher)} {'  '}
                    class
                  </p>
                  <p className="font-light">
                    ({getNumWithOrdinal(participant?.class_count_overall)} overall)
                  </p>
                  <div className="rounded-full h-1.5 w-1.5 bg-gray-300 mr-2 ml-4" />{' '}
                  <p className="font-light">{participant?.student_type}</p>
                </span>
              </div>

              {/* participant more options */}
              {/* btn for more options or banned users START */}
              {participant?.is_banned === 1 ? (
                <button
                  className="disabled:opacity-50 text-12px font-normal md:w-78px font-inter disabled-btn-danger absolute right-4 text-xs rounded p-2 cursor-not-allowed flex"
                  disabled
                >
                  <img
                    src="https://images.myyogateacher.com/ban.png"
                    alt="banned"
                    className="w-4 h-4 inline mr-2"
                  />
                  Banned
                </button>
              ) : (
                <div id={participant.student_uuid} onClick={this.handleOptionsChange}>
                  <img
                    alt="participant options"
                    src="https://images.myyogateacher.com/double_arrow.png"
                    className="absolute right-6 top-6 cursor-pointer"
                  />
                  {this.render_options(participant)}
                </div>
              )}
              {/* btn for more options or banned users ENDS */}
            </li>
          ))
      ) : (
        <div className="py-4 text-center">No data found</div>
      )}
    </ul>
  );

  handleGoBack = () => {
    this.setState({
      show_ban_popup: false
    });
  };

  render_ban_alert = () => (
    <div className="mt-6 mx-6">
      <div className="p-4 border bg-mytyellow rounded-10 border-none ">
        <p className="h-auto w-auto break-words text-mytyellowtext font-medium leading-snug">
          Banning will prevent {this.state.banParticipantData?.student_name} from having access to
          the account. Care team will contact you for further information.
        </p>
      </div>
      <div className="block">
        <h4 className={`mt-8 text-19 text-left ${this.state.selectAlert && 'text-mytRedText'}`}>
          Please select reason to ban {this.state.banParticipantData?.student_name}
        </h4>
        {this.props.config_values?.student_ban_reasons.map((reason, index) => (
          <div className="mt-6 mb-6" id={index}>
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="w-5 h-5"
                onChange={this.handleBanReason}
                checked={this.state.checkedBanReasons.indexOf(reason) !== -1}
                value={reason}
                id={index}
              />
              <span className="ml-4 text-sm font-normal">{reason}</span>
            </label>
          </div>
        ))}

        <div className="mt-6 mb-6">
          <label className="inline-flex items-center">
            <input
              type="checkbox"
              className="w-5 h-5 bg-gray-600"
              onChange={this.handleAllBanReason}
              checked={this.state.isAllReasonSelected}
              value="All of the above"
            />
            <span className="ml-4 text-sm font-normal">All of the above</span>
          </label>
        </div>
      </div>
      <div className="flex flex-col">
        <button className="w-full btn-danger p-2 mb-3 rounded-lg" onClick={this.onBanParticipant}>
          Ban {this.state.banParticipantData?.student_name}
        </button>
        <button className="w-full btn-white p-2 rounded-lg mb-8" onClick={this.handleGoBack}>
          Go back
        </button>
        {this.state.banloading && <CustomLoading />}
      </div>
    </div>
  );

  onBanParticipant = () => {
    if (checkedBanReasons.length !== 0) {
      axios({
        method: 'post',
        url: urls.ban_student,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getCookie('teacher_token_1')}`
        },
        data: {
          student_uuid: this.state.banParticipantData?.student_uuid,
          reason: this.state.checkedBanReasons.join(' , ')
        }
      })
        .then((response) => {
          this.setState({
            banloading: false,
            banParticipant: true
          });
        })
        .catch((error) => {
          this.setState({
            banloading: false
          });
        });
    } else {
      this.setState({
        selectAlert: true
      });
    }
  };

  render_banned_participant = () => (
    <div className="py-20 px-6 font-medium flex flex-col items-center">
      <div>
        <img
          src="https://images.myyogateacher.com/check_icon.png"
          alt="confirm"
          className="w-11 h-11 mb-4"
        />
      </div>
      <div className="text-center text-mytgrey-subText">
        {' '}
        {this.state.banParticipantData?.student_name} banned for{' '}
        {this.state.checkedBanReasons.join(' , ')}
      </div>
    </div>
  );

  findChannel = () => {
    switch (this.props.item.session_type) {
      case 'YOGA_WITH_FRIENDS_SESSION':
        return this.props.item.yoga_with_friends_group_chat_id;
      case 'WORKSHOP_SESSION':
      case 'GROUP_SESSION':
        return this.props.item.group_chat_channel_id;
      default:
        return ChannelUtils.makeChannel(this.props.item.student_uuid, UserRole.STUDENT);
    }
  };

  openChat = () => {
    switch (this.props.item.session_type) {
      case 'YOGA_WITH_FRIENDS_SESSION':
      case 'WORKSHOP_SESSION':
      case 'GROUP_SESSION':
        ChatService.openGroupChat(this.findChannel());
        break;
      default:
        ChatService.openP2PChat(this.props.item.student_uuid);
        break;
    }
  };

  getReloadData = () => {
    this.props.load_short_dashboard_sessions();
  };

  closeScheduleClassPopup = () => {
    this.setState({
      ScheduleClass: false
    });
  };

  closeSchedulePopup = (prevProps) => {
    this.setState({
      ScheduleClass: false,
      loading: true
    });
    this.props.load_short_dashboard_sessions();
  };

  openCreditPopup = (dt) => {
    this.setState({
      subscritiopnPopupDataDT: dt,
      ScheduleClass: false,
      subscritiopnPopup: true
    });
  };

  closesubscriptionPopupFail = () => {
    this.setState({
      editPopupData: this.props.scheduleSessionDataInfo.subscriptionPayload,
      subscritiopnPopup: false,
      ScheduleClass: true
    });
  };

  closesubscriptionPopup = () => {
    this.setState({
      subscritiopnPopup: false,
      sessionScheduledSuccess: true
    });
    this.props.load_short_dashboard_sessions();
  };

  closesubscriptionPopupFun = () => {
    this.setState({
      ScheduleClass: false,
      sessionScheduledSuccess: true
    });
    this.props.load_short_dashboard_sessions();
  };

  closeSessionSuccess = () => {
    this.setState({
      sessionScheduledSuccess: false
    });
  };

  closeSubscriptionPopupdt = () => {
    this.setState({
      subscritiopnPopup: false
    });
    this.props.load_short_dashboard_sessions();
    this.props.set_home_variable('scheduleSessionDataInfoErrMsg', '');
    this.props.set_home_variable('scheduleSessionDataInfo', {});
  };

  triggerLiveKitJoin = async () => {
    const { session_uuid } = this.props.item;
    try {
      const payload = {
        session_uuid
      };
      const res = await post_api(urls.livekit_join, payload, true);
      window.open(
        `https://${window.location.host}/sessions/livekit?session_uuid=${session_uuid}`,
        '_blank'
      );
    } catch (error) {
      console.log(error, 'error');
    }
  };

  // ----------------------------------- //

  getBgColor = (sessionType) => {
    switch (sessionType) {
      case 'GROUP_SESSION':
        return `#EA6DA1`;
      case 'YOGA':
        return `#0397BA`;
      case 'GROUP_SESSION_STUDENT':
        return `#EA6DA1`;
      case 'VIDEO_CONSULT':
        return `#EE2A7B`;
      case 'WORKSHOP_SESSION':
        return `#EE2A7B`;
      case 'WORKSHOP_SESSION_STUDENT':
        return `#EE2A7B`;
      case 'YOGA_WITH_FRIENDS_SESSION':
        return `#4D1EF0`;
      case 'YOGA_WITH_FRIENDS_SESSION_STUDENT':
        return `#4D1EF0`;
      case 'BACK_PAIN_CONSULT':
      case 'BACK_PAIN_THERAPY':
        return `#4D1EF0`;
      default:
        return `#0397BA`;
    }
  };

  openMoreOption = () => {
    this.setState({ moreOptions: !this.state.moreOptions });
  };

  isSessionYogaOrYWF = () =>
    this.props.item?.session_type === 'YOGA' ||
    this.props.item?.session_type === 'YOGA_WITH_FRIENDS_SESSION_STUDENT' ||
    this.props.item?.session_type === 'YOGA_WITH_FRIENDS_SESSION';

  open_pain_level_popup = () => {
    this.setState({ showPainLevelPopup: true });
  };

  copyDataInDevMode = (e) => {
    if (isDevMode()) {
      const id = this.props.item.session_uuid;
      let url = urls.hridiya_session_details;
      url = `${url}?id=${id}`;
      copyTextToClipboardinHttp(url);
    }
  };

  formatTrialDay = (trial_day) => {
    let suffix = 'th';

    if (trial_day % 10 === 1 && trial_day % 100 !== 11) {
      suffix = 'st';
    } else if (trial_day % 10 === 2 && trial_day % 100 !== 12) {
      suffix = 'nd';
    } else if (trial_day % 10 === 3 && trial_day % 100 !== 13) {
      suffix = 'rd';
    }

    return `Trial (${trial_day}${suffix} day)`;
  };

  updateRemainingTime = () => {
    const { item } = this.props;
    const startTime = item?.epoch_start_time;
    const m1 = moment();
    const m2 = moment(startTime);
    const t = m2.diff(m1, 'minutes');

    let remainingTime = '';

    if (t < 6) {
      remainingTime = 'Join Now';
    } else {
      const days = Math.floor(t / (60 * 24));
      const hours = Math.floor((t % (60 * 24)) / 60);
      const minutes = t % 60;

      if (days > 0) {
        remainingTime = `${days} day${days > 1 ? 's' : ''}`;
      }

      if (hours > 0) {
        remainingTime += ` ${hours} hour${hours > 1 ? 's' : ''}`;
      }

      if (minutes > 0 && days === 0 && hours === 0) {
        remainingTime += ` ${minutes} min${minutes > 1 ? 's' : ''}`;
      }

      remainingTime = `Join in ${remainingTime}`;
    }

    this.setState({ remainingTime });
  };

  handleClickReplacement = () => {
    const { item } = this.props;
    const isOneOnOne = item?.session_type === 'YOGA';
    const isTrialUser = isOneOnOne && item?.trial_day > 0;
    const allowReplacement = isTrialUser
      ? () => this.contactCareTeam()
      : () => this.toggle_replacement();

    const cancelSession = isTrialUser
      ? () => this.contactCareTeam()
      : () =>
          this.setState({
            cancel_alert_open: !this.state.cancel_alert_open,
            moreOptions: false
          });

    if (item.allow_teacher_replacement) {
      allowReplacement();
    } else {
      cancelSession();
    }
  };

  contactCareTeam = () => {
    ChatService.openCareChat();
  };

  render() {
    const currentDate = new Date().valueOf();
    const startEpochDateTime = moment(currentDate);
    const endEpochDateTime = moment(this.props.item.epoch_start_time);
    const hourDiffDataDt = endEpochDateTime.diff(startEpochDateTime, 'minutes');

    const dash_class =
      this.props.item.replacement_request_status === 'ACTIVE' ? 'dashboard-re-sess' : '';
    const parti_count = this.props.item.participants;

    const parti_str = this.props.item?.participants_text
      ? this.props.item.participants_text
      : `${parti_count} Registered`;
    const session_details = this.props.item;
    const is_group_session =
      session_details.session_type === 'GROUP_SESSION' ||
      session_details.session_type === 'GROUP_SESSION_STUDENT';
    const is_party_session = session_details.session_type === 'YOGA_WITH_FRIENDS_SESSION_STUDENT';
    const teacher_local_time = `${session_details.teacher_local_time} (${session_details.duration} min)`;

    const roadmap_btn_fn =
      this.props.item.is_road_map_created === 0
        ? this.create_roadmap
        : this.open_student_add_roadmap;
    const is_map_created = this.props.item.is_road_map_created !== 0;

    const show_roadmap_btn =
      this.props.item.is_road_map_created === 0 ||
      (this.props.item.is_road_map_created === 1 && this.props.item.session_recap_btn === 1);

    const start_in_minutes =
      Math.floor((this.props.item.epoch_start_time - moment().valueOf()) / 1000) / 60;

    const isBackPainTherapy = this.props.item?.session_type === 'BACK_PAIN_THERAPY';
    const isBackPainConsult = this.props.item?.session_type === 'BACK_PAIN_CONSULT';
    const isBackPainGroup =
      this.props.item?.session_type === 'GROUP_SESSION' &&
      this.props.item?.is_exclusive_for_backpain_client === 1;

    const isOneOnOne = this.props.item?.session_type === 'YOGA';
    const isGroupClass = this.props.item?.session_type === 'GROUP_SESSION';
    const isEvent = this.props.item?.is_event === 1;

    const { item } = this.props;
    const hasAttendText = item?.session_attend_text;

    let cardBGColor = 'bg-white';

    if (isGroupClass && !isEvent && hasAttendText) {
      cardBGColor = 'bg-newCradGCBGColor border border-newCradGCBorderColor';
    } else if (isOneOnOne && hasAttendText) {
      cardBGColor = 'bg-newCradOneOnOneBGColor border border-newCradOneOnOneBorderColor';
    }
    const isTrialUser = isOneOnOne && this.props.item?.trial_day > 0;

    return (
      <div id={this.props.index}>
        {this.state.loading ? <CustomLoading /> : null}
        {this.state.open_join_popup &&
        this.props.selected_dashboard_session_uuid === this.props.item.session_uuid
          ? this.render_safari_popup()
          : null}
        {this.state.showPainLevelPopup ? (
          <PainLevelPopup
            student_uuid={this.props.item.student_uuid}
            onClose={() => {
              this.setState({
                showPainLevelPopup: false
              });
            }}
          />
        ) : null}

        {this.state.ScheduleClass && (
          <DashboardScheduleClassPopup
            editPopupData={this.props.item}
            closePopup={this.closeScheduleClassPopup}
            closeSchedulePopup={this.closeSchedulePopup}
            openCreditPopup={(dt) => this.openCreditPopup(dt)}
            closesubscriptionPopupFun={this.closesubscriptionPopupFun}
          />
        )}

        {this.state.sessionScheduledSuccess && (
          <ScheduleConfirmation closeSessionSuccess={this.closeSessionSuccess} />
        )}
        {this.state.subscritiopnPopup && (
          <SubscritionPopup
            closesubscriptionPopupFail={this.closesubscriptionPopupFail}
            closesubscriptionPopup={this.closesubscriptionPopup}
            subscritiopnPopupDataDT={this.state.subscritiopnPopupDataDT}
            closePopup={this.closeSubscriptionPopupdt}
            subscriptionPopupData="a093d28d-76c5-4b6a-9b26-bda1ba53515c"
          />
        )}
        {!!this.props.item.allow_teacher_replacement && !this.state.consultCallActive
          ? this.render_request_replacement()
          : null}
        {this.render_cancel_alert()}
        <div className={`dashboardContainerWidth pb-2 upComingClasses relative${dash_class}`}>
          {this.state.open_student_profile ? (
            <StudentProfile
              closeFunc={this.close_student_profile}
              student_uuid={this.props.item.student_uuid}
            />
          ) : null}

          <div className="flex justify-start mb-2.5">
            <div className="font-inter lg:font-bold zm:font-extrabold text-20px zm:leading-24px lg:leading-28px">
              {teacher_local_time}
            </div>
          </div>
          {isGroupClass && !isEvent && hasAttendText && (
            <div className="px-20px py-21px bg-newCradListGCBGColor font-medium rounded-tl-lg rounded-tr-lg border border-newCradGCBorderColor flex sm:flex-row zm:flex-col sm:items-center justify-between gap-5px">
              <p className="text-15px font-medium text-newCradListGCTextColor">
                {this.props.item?.session_attend_text}
              </p>
              <p
                className="text-13px font-semibold leading-normal text-newCradListGCTextColor flex gap-10px items-center cursor-pointer"
                onClick={() => {
                  this.setState({ loading: true });
                  this.show_participant_list(this.props.item.session_uuid);
                }}
              >
                View Student Profile{' '}
                <span>
                  <PinkRightArrow />
                </span>
              </p>
            </div>
          )}
          {isOneOnOne && hasAttendText && (
            <div className="px-20px py-21px bg-newCradListOneOnOneBGColor font-medium rounded-tl-lg rounded-tr-lg border border-newCradOneOnOneBorderColor flex sm:flex-row zm:flex-col sm:items-center justify-between gap-5px">
              <p className="text-15px font-medium text-newCradListOneOnOneTextColor">
                {HtmlReactParser(this.props.item?.session_attend_text)}
              </p>
              <p
                className="text-13px font-semibold leading-normal text-newCradListOneOnOneTextColor flex gap-10px items-center cursor-pointer"
                onClick={this.open_student_profile}
              >
                View Student Profile{' '}
                <span>
                  <BlueRightArrow />
                </span>
              </p>
            </div>
          )}
          <div
            className={`${
              isBackPainTherapy || isBackPainConsult || isBackPainGroup
                ? 'bg-consultantGradient border-t-4 border-t-YWFBGColor'
                : `${cardBGColor} border-t-0`
            } md:p-30px zm:p-5 shadow-mytboxshadow ${hasAttendText ? 'rounded-bl-lg rounded-br-lg' : 'rounded-lg'}`}
            onClick={(e) => {
              if (isDevMode()) {
                this.copyDataInDevMode(e);
              }
            }}
          >
            <div className="flex lg:flex-row zm:flex-col xl:items-center justify-between w-full upComingClassesInnerContainer">
              <div className="flex md:flex-row zm:flex-col md:items-center zm:items-start w-full">
                <div className="relative">
                  {this.props.item?.session_type === 'VIDEO_CONSULT' ? (
                    <svg
                      width="70"
                      height="70"
                      viewBox="0 0 70 70"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="70" height="70" rx="35" fill="#F1E7FF" />
                      <path
                        d="M48.2154 42.6406L48.0654 42.3162C47.6154 41.2531 42.0729 39.6237 41.6135 39.5975L41.246 39.6256C40.4904 39.7812 39.6579 40.4975 37.9104 42.0462C35.9416 41.135 33.2454 38.7068 31.9741 37.2725C30.5979 35.7218 28.8804 33.1137 28.2635 31.1656C30.2566 29.3993 31.0873 28.6325 31.1398 27.7793C31.1679 27.32 30.2173 21.6256 29.216 21.0481L28.916 20.8587C28.2785 20.4481 27.3148 19.8275 26.2441 20.045C25.9854 20.0993 25.7323 20.2006 25.496 20.3468C24.7779 20.8006 23.0191 22.0212 22.2166 23.6168C21.7216 24.6106 21.4966 33.6781 28.2935 41.3431C35.0079 48.92 43.2879 49.25 44.531 48.995L44.5666 48.9893L44.6772 48.9556C46.3573 48.3537 47.7804 46.7525 48.3148 46.0925C49.3029 44.87 48.5979 43.4187 48.2154 42.6406Z"
                        fill="#7D4AC3"
                      />
                    </svg>
                  ) : (
                    <img
                      className="cursor-pointer zm:h-46px zm:w-46px md:w-70px md:h-70px rounded-full"
                      src={this.props.item.avatar_medium}
                      onClick={() => {
                        this.props.item.session_type === 'BACK_PAIN_CONSULT' ||
                        this.props.item.session_type === 'BACK_PAIN_THERAPY'
                          ? this.props.router.navigate(
                              `/backpain-client-profile?id=${this.props.item.student_uuid}`,
                              {
                                state: {
                                  student_time: this?.props?.item?.student_local_time || ''
                                }
                              }
                            )
                          : is_group_session
                            ? null
                            : this.openProfilePage(this.props.item.student_slug);
                      }}
                      alt="avatar"
                    />
                  )}
                  {this.props.item?.session_type !== 'VIDEO_CONSULT' && (
                    <div
                      className="absolute bottom-0 right-0 p-1 zm:w-16px zm:h-16px md:w-22px md:h-22px"
                      style={{
                        backgroundColor: this.getBgColor(this.props.item?.session_type),
                        borderRadius: '100%'
                      }}
                    >
                      {isBackPainTherapy || isBackPainConsult ? null : (
                        <img
                          className="w-full h-full"
                          style={{ filter: 'brightness(0) invert(1)' }}
                          src={this.props.item.session_icon}
                          alt="icon"
                        />
                      )}
                    </div>
                  )}
                </div>
                <div className="md:pt-0 zm:pt-2 md:ml-5 flex zm:flex-col justify-between zm:w-full lg:w-88%">
                  <div className="flex dashboard-sessions-list-style lg:justify-between">
                    <div className="dashboard-session-details-name" style={{ cursor: 'default' }}>
                      <div>
                        <p className="text-black font-inter lg:text-19px zm:text-16px font-medium lg:leading-23px zm:leading-19px">
                          {is_group_session
                            ? session_details.group_session_name
                            : this.props.item.student_name}
                        </p>
                        <p className="flex-col gap-5px  flex md:text-13px zm:text-xs font-medium font-inter text-startTimeTextColor zm:pt-5px lg:pt-2px mb-5px zm:leading-15px lg:leading-16px">
                          {this.isSessionYogaOrYWF() ? (
                            <>
                              {(this.props.item?.yoga_level).charAt(0).toUpperCase() +
                                (this.props.item?.yoga_level).slice(1).toLowerCase()}
                              &nbsp;&nbsp; |&nbsp;&nbsp;
                              {getNumWithOrdinal(this.props.item?.number_of_past_sessions + 1)}{' '}
                              class&nbsp;&nbsp; |&nbsp;&nbsp;
                              {this.props.item?.student_local_time}
                            </>
                          ) : (
                            <>
                              {`${
                                (this.props.item?.yoga_level).charAt(0).toUpperCase() +
                                (this.props.item?.yoga_level).slice(1).toLowerCase()
                              }`}
                              &nbsp;
                              {!this.isSessionYogaOrYWF() &&
                              this.props.item?.yoga_level?.toLowerCase() !== 'all levels' ? (
                                isBackPainTherapy || isBackPainConsult ? (
                                  <>
                                    {getNumWithOrdinal(
                                      this.props.item?.number_of_past_sessions + 1
                                    )}{' '}
                                    Session |&nbsp;&nbsp;
                                    {this.props.item?.student_local_time}
                                  </>
                                ) : (
                                  'level'
                                )
                              ) : (
                                ''
                              )}
                              &nbsp;&nbsp;
                            </>
                          )}
                          {this.props.item?.session_type === 'VIDEO_CONSULT' && (
                            <>|&nbsp;&nbsp;{this.props.item?.student_local_time}</>
                          )}
                          {(is_group_session || is_party_session) && (
                            <div
                              className="cursor-pointer flex items-center gap-5px w-fit"
                              onClick={() => {
                                this.setState({ loading: true });
                                this.show_participant_list(this.props.item.session_uuid);
                              }}
                            >
                              {/* {window.innerWidth > 450 && <>&nbsp;&nbsp;|&nbsp;&nbsp;</>} */}
                              {parti_str}
                              <RegisteredIcon />
                            </div>
                          )}
                        </p>
                        <div className="flex items-center gap-10px zm:mt-10px lg:mt-5px">
                          {this.props.item?.session_type === 'GROUP_SESSION' && (
                            <p className="bg-mytClassReplace font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              Group class
                            </p>
                          )}
                          {this.props.item?.session_type === 'YOGA' && (
                            <p className="bg-mytBgReplacementClassType font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              1-on-1 (
                              {getNumWithOrdinal(this.props.item?.session_number_for_session_type)}{' '}
                              class)
                            </p>
                          )}
                          {this.props.item?.session_type === 'GROUP_SESSION_STUDENT' && (
                            <p className="bg-mytClassReplace font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              Group Session
                            </p>
                          )}
                          {this.props.item?.session_type === 'VIDEO_CONSULT' && (
                            <p className="bg-mytLitePurpleTextDefaultV1 font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              {' '}
                              Yoga consultation
                            </p>
                          )}
                          {this.props.item?.session_type === 'WORKSHOP_SESSION' && (
                            <p className="bg-workShopBGColor font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              Workshop
                            </p>
                          )}
                          {this.props.item?.session_type === 'WORKSHOP_SESSION_STUDENT' && (
                            <p className="bg-videoConsultBGColor font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              Workshop
                            </p>
                          )}
                          {this.props.item?.session_type === 'YOGA_WITH_FRIENDS_SESSION' && (
                            <p className="bg-YWFBGColor font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              Yoga with Friends (
                              {getNumWithOrdinal(
                                this.props.item?.session_number_for_session_type + 1
                              )}{' '}
                              class)
                            </p>
                          )}
                          {this.props.item?.session_type ===
                            'YOGA_WITH_FRIENDS_SESSION_STUDENT' && (
                            <p className="bg-YWFBGColor font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              Yoga with Friends (
                              {getNumWithOrdinal(
                                this.props.item?.session_number_for_session_type + 1
                              )}{' '}
                              class)
                            </p>
                          )}
                          {this.props.item?.is_recurring == 1 && (
                            <p
                              style={{ backgroundColor: '#321A50' }}
                              className="font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px"
                            >
                              Repeat class
                            </p>
                          )}
                          {this.props.item?.student_is_member === 1 && (
                            <p className="bg-memberBGColor font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              Member
                            </p>
                          )}
                          {isBackPainTherapy ? (
                            <>
                              <p className=" bg-YWFBGColor text-mytWhiteReplace zm:text-10px font-semibold px-4px py-3px rounded">
                                1-on-1
                              </p>

                              <p className=" bg-YWFBGColor text-mytWhiteReplace zm:text-10px font-semibold px-4px py-3px rounded">
                                Back Pain Coaching
                              </p>
                            </>
                          ) : null}
                          {isBackPainConsult ? (
                            <>
                              <p className=" bg-YWFBGColor text-mytWhiteReplace zm:text-10px font-semibold px-4px py-3px rounded">
                                Consult
                              </p>

                              <p className=" bg-YWFBGColor text-mytWhiteReplace zm:text-10px font-semibold px-4px py-3px rounded">
                                Back Pain Coaching
                              </p>
                            </>
                          ) : null}
                          {isBackPainGroup ? (
                            <p className=" bg-YWFBGColor text-mytWhiteReplace zm:text-10px font-semibold px-4px py-3px rounded">
                              Back Pain Coaching
                            </p>
                          ) : null}
                          {this.props.item?.trial_day > 0 ? (
                            <p className=" bg-memberBGColor font-inter text-10px font-bold text-white rounded-4px px-4px py-3px w-auto flex items-center h-18px leading-12px">
                              {this.formatTrialDay(this.props.item?.trial_day)}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {this.props.item.status !== 'CANCELLED' &&
                    this.props.item.teacher_uuid === this.props.teacher_details.uuid ? (
                      <>
                        <TestYourSetup
                          test_setup_open={this.state.test_setup_open}
                          close_test_setup={this.close_test_setup}
                        />
                        <div className="flex xs:flex-row zm:flex-col gap-10px zm:w-full md:w-auto">
                          {this.props.item.session_type !== 'VIDEO_CONSULT' ? (
                            <div className="w-full flex zm:w-full xs:w-auto items center justify-start">
                              <div className="relative zm:w-full xs:w-max">
                                <div
                                  disabled={hourDiffDataDt >= 30}
                                  className={`btn btn-green h-46px md:w-max zm:w-full ${
                                    hourDiffDataDt >= 30
                                      ? 'cursor-not-allowed opacity-50 pointer-events-none'
                                      : 'cursor-pointer'
                                  }`}
                                  onClick={this.handleJoinMeetingButton}
                                  data-div_session_uuid={this.props.item.session_uuid}
                                >
                                  <a className="text-white font-semibold text-15 relative">
                                    {this.state.remainingTime}
                                  </a>
                                </div>
                                {this.props.item.is_live === 1 ? (
                                  <span className="absolute rounded-4px flex items-center h-18px -top-5px -right-5px bg-mytLabelRed px-4px py-3px text-12px font-bold text-white">
                                    Live
                                  </span>
                                ) : null}
                              </div>
                            </div>
                          ) : this.state.consultCallActive ? null : (
                            <div
                              className={`btn flex justify-center items-center h-46px  select-none zm:w-full xs:w-fit  ${
                                start_in_minutes > 0
                                  ? 'cursor-not-allowed btn-green opacity-50'
                                  : 'cursor-pointer btn-green'
                              }`}
                              onClick={() => {
                                if (!this.props.makeCall && start_in_minutes < 1) {
                                  this.props.set_home_variable('makeCall', true);
                                  this.props.set_home_variable('session', this.props.item);
                                  this.setState({ consultCallActive: true });
                                } else {
                                  this.props.set_home_variable('makeCall', false);
                                }
                              }}
                            >
                              <p className="text-white font-semibold text-15px text-inter w-max">
                                {start_in_minutes < 10
                                  ? `Call${
                                      start_in_minutes > 0
                                        ? ` in ${
                                            Math.floor(start_in_minutes) === 0
                                              ? 1
                                              : Math.floor(start_in_minutes)
                                          } minutes`
                                        : ' Now'
                                    }`
                                  : 'Call'}
                              </p>
                            </div>
                          )}
                          {this.props.item.session_type === 'YOGA' && show_roadmap_btn ? (
                            <button className="add-roadmap-dash-btn" onClick={roadmap_btn_fn}>
                              {this.props.item.roadmap_completed === 0 ? (
                                <img
                                  alt="to do"
                                  className="ds_tag_to_do"
                                  src="https://images.myyogateacher.com/icons/tag_to_do.svg"
                                />
                              ) : null}
                              {is_map_created
                                ? this.props.item.session_recap_text
                                : 'Create roadmap'}
                              {is_map_created ? (
                                <span>{this.props.item.session_recap_sub_text}</span>
                              ) : null}
                            </button>
                          ) : null}
                          {this.props.item.session_type === 'VIDEO_CONSULT' ? (
                            <div className="flex zm:mt-10px md:mt-0">
                              <button
                                className="zm:w-80px h-46px btn-white-default p-0 lg:w-58px md:ml-10px mr-10px"
                                onClick={() => {
                                  this.closeViewParticiPop();
                                  ChatService.openP2PChat(this.props.item.student_uuid);
                                }}
                              >
                                <img
                                  className=""
                                  src="https://images.myyogateacher.com/web_images/header/v1/chat-icon.svg"
                                  alt="icon"
                                />
                              </button>
                              <div className="relative zm:w-full xs:w-auto">
                                <button
                                  className="md:w-auto zm:w-full w-auto relative btn h-46px border border-mytgrey-subBorder focus:outline-none shadow-mytboxshadow"
                                  onClick={this.openMoreOption}
                                >
                                  <span className="text-mytpurple font-semibold text-15 relative flex items-center">
                                    More
                                    <svg
                                      className="ml-10px"
                                      width="19"
                                      height="21"
                                      viewBox="0 0 19 21"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M4.22266 8.5L10.556 14.8L16.8893 8.5"
                                        stroke="#321A50"
                                        strokeWidth="2.2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                    </svg>
                                  </span>
                                </button>
                                {this.state.moreOptions ? (
                                  <ClickDetect close_card={this.openMoreOption}>
                                    <div className="absolute right-0 top-55px py-25px w-max bg-white flex flex-col justify-center border border-gray-200 shadow-mytboxshadow rounded-lg px-30px z-1 ml-1 gap-12px">
                                      <div className="cursor-pointer">
                                        <p
                                          onClick={() =>
                                            this.openProfilePage(this.props.item.student_slug)
                                          }
                                          className="class-btn-size text-black font-inter font-semibold text-15.5px leading-19px"
                                        >
                                          View Profile
                                        </p>
                                      </div>

                                      {Object.keys(this.props.item.back_pain_levels.length > 0) ? (
                                        <div
                                          className="class-btn-size text-black font-inter font-semibold text-15.5px leading-19px"
                                          onClick={this.open_pain_level_popup}
                                        >
                                          View Pain Level
                                        </div>
                                      ) : null}
                                      {!this.state.consultCallActive
                                        ? [
                                            <p
                                              onClick={() => {
                                                this.cancel_session();
                                              }}
                                              className="class-btn-size cursor-pointer text-black font-inter font-semibold text-15.5px leading-19px"
                                            >
                                              Cancel consult
                                            </p>
                                          ]
                                        : null}

                                      {this.state.consultCallActive
                                        ? [
                                            this.state.consultCallActive ? (
                                              <div
                                                className="flex items-center"
                                                onClick={() => {
                                                  this.setState({
                                                    showHelpfulQuestionsPopup: true
                                                  });
                                                }}
                                              >
                                                <p className="cursor-pointer class-btn-size text-black font-inter font-semibold text-15.5px leading-19px">
                                                  Helpful questions for consult
                                                </p>
                                              </div>
                                            ) : null,
                                            <div
                                              onClick={this.impersonateStudent}
                                              className="flex items-center"
                                            >
                                              <p className="cursor-pointer class-btn-size text-black font-inter font-semibold text-15.5px leading-19px">
                                                {this.state.consultCallActive
                                                  ? 'Recommended classes'
                                                  : 'Book a session'}
                                              </p>
                                            </div>
                                          ]
                                        : null}
                                    </div>
                                  </ClickDetect>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <div className="w-full">
                              <div className="flex items-center md:w-auto zm:w-full gap-10px">
                                {isBackPainTherapy || isBackPainConsult ? null : !this.state
                                    .consultCallActive ? (
                                  <button
                                    className="zm:w-80px h-46px btn-white-default p-0 lg:w-58px"
                                    onClick={() => {
                                      this.openChat();
                                    }}
                                  >
                                    <img
                                      className=""
                                      src="https://images.myyogateacher.com/web_images/header/v1/chat-icon.svg"
                                      alt="icon"
                                    />
                                    {/* {this.state.channelUnread > 0 ? (
																				<div className='custom-chat-badge'>
																					{this.state.channelUnread}
																				</div>
																			) : null} */}
                                  </button>
                                ) : null}
                                <div className="relative zm:w-full lg:w-auto">
                                  <div
                                    className="bg-white xs:w-auto zm:w-full w-auto relative btn h-46px border border-mytgrey-subBorder shadow-mytboxshadow cursor-pointer"
                                    onClick={this.openMoreOption}
                                  >
                                    <span className="text-mytpurple font-semibold text-15 relative flex items-center">
                                      More
                                      <svg
                                        className="ml-10px"
                                        width="19"
                                        height="21"
                                        viewBox="0 0 19 21"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M4.22266 8.5L10.556 14.8L16.8893 8.5"
                                          stroke="#321A50"
                                          strokeWidth="2.2"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </span>
                                  </div>
                                  {this.state.moreOptions ? (
                                    <ClickDetect close_card={this.openMoreOption}>
                                      <div className="absolute right-0 top-55px py-25px w-max bg-white flex flex-col justify-center border border-gray-200 shadow-mytboxshadow rounded-lg cursor-pointer px-30px z-1 ml-1 gap-12px">
                                        {this.props.item.session_type === 'GROUP_SESSION' ||
                                        this.props.item.session_type ===
                                          'WORKSHOP_SESSION' ? null : (
                                          <>
                                            {this.props.item.session_type === 'BACK_PAIN_CONSULT' ||
                                            this.props.item.session_type === 'BACK_PAIN_THERAPY' ? (
                                              <div
                                                className="class-btn-size text-black font-inter font-semibold text-15.5px leading-19px"
                                                onClick={() =>
                                                  this.props.router.navigate(
                                                    `/backpain-client-profile?id=${this.props.item.student_uuid}`,
                                                    {
                                                      state: {
                                                        student_time:
                                                          this?.props?.item?.student_local_time ||
                                                          ''
                                                      }
                                                    }
                                                  )
                                                }
                                              >
                                                View Profile
                                              </div>
                                            ) : null}
                                            {this.props.item.session_type ===
                                            'YOGA_WITH_FRIENDS_SESSION_STUDENT' ? null : this.props
                                                .item.session_type !== 'BACK_PAIN_CONSULT' &&
                                              this.props.item.session_type !==
                                                'BACK_PAIN_THERAPY' ? (
                                              <div
                                                className="class-btn-size text-black font-inter font-semibold text-15.5px leading-19px"
                                                onClick={this.open_student_profile}
                                              >
                                                {this.props.item.student_name}
                                                's profile
                                              </div>
                                            ) : null}

                                            {this.props.item.session_type !== 'VIDEO_CONSULT' &&
                                              this.props.item?.session_type !==
                                                'BACK_PAIN_CONSULT' &&
                                              this.props.item.session_type !==
                                                'BACK_PAIN_THERAPY' &&
                                              this.props.item.teacher_uuid ===
                                                this.props.teacher_details.uuid &&
                                              this.props.item.replacement_request_status !==
                                                'ACTIVE' &&
                                              this.props.item.session_type !==
                                                'YOGA_WITH_FRIENDS_SESSION_STUDENT' && (
                                                <div
                                                  className="class-btn-size text-black font-inter font-semibold text-15.5px leading-19px"
                                                  onClick={() => {
                                                    this.change_time(this.props.item.session_uuid);
                                                  }}
                                                >
                                                  Edit Class Details
                                                </div>
                                              )}
                                          </>
                                        )}
                                        {this.props.item.session_type === 'YOGA' && (
                                          <div
                                            onClick={this.openScheduleClassPopup}
                                            className="class-btn-size text-black font-inter font-semibold text-15.5px leading-19px"
                                          >
                                            Schedule new 1-on-1 session
                                          </div>
                                        )}
                                        {this.props.item.session_type !== 'BACK_PAIN_CONSULT' &&
                                        this.props.item.session_type !== 'BACK_PAIN_THERAPY' ? (
                                          <div
                                            className="class-btn-size text-black font-inter font-semibold text-15.5px leading-19px"
                                            onClick={this.open_test_setup}
                                          >
                                            Test your setup
                                            <TestYourSetup
                                              test_setup_open={this.state.test_setup_open}
                                              close_test_setup={this.close_test_setup}
                                            />
                                          </div>
                                        ) : null}
                                        {this.props.item.session_type !== 'BACK_PAIN_THERAPY' &&
                                        this.props?.item?.show_follow_up_consult_button ? (
                                          <div
                                            className="class-btn-size text-black font-inter font-semibold text-15.5px leading-19px"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              this.props.router.navigate(`/book-consult`, {
                                                state: {
                                                  student_uuid: this.props.item.student_uuid,
                                                  student_name: this.props.item.student_name
                                                }
                                              });
                                            }}
                                          >
                                            Book follow-up consult
                                          </div>
                                        ) : null}
                                        {this.props?.item?.is_backpain_subscribed === 1 ? (
                                          <div
                                            className="class-btn-size text-black font-inter font-semibold text-15.5px leading-19px"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              this.props.router.navigate(
                                                `/book-coaching?id=${this.props.item.student_uuid}`,
                                                {
                                                  state: {
                                                    timeZone: this.props.item.student_local_time
                                                  }
                                                }
                                              );
                                            }}
                                          >
                                            Book Back pain 1-on-1
                                          </div>
                                        ) : null}
                                        {this.props?.item?.is_backpain_subscribed === 1 &&
                                        this.props.item?.session_type !== 'BACK_PAIN_CONSULT' ? (
                                          <GroupButton
                                            student_uuid={this.props.item.student_uuid}
                                          />
                                        ) : null}
                                        {this.props.item.teacher_uuid ===
                                          this.props.teacher_details.uuid &&
                                          this.props.item.replacement_request_status !== 'ACTIVE' &&
                                          this.props.item?.session_type !== 'BACK_PAIN_CONSULT' && (
                                            <div
                                              className="class-btn-size text-mytLabelRed font-inter font-semibold text-15.5px leading-19px"
                                              onClick={this.handleClickReplacement}
                                            >
                                              {this.props.item.status !== 'ACTIVE'
                                                ? this.props.item.allow_teacher_replacement
                                                  ? isTrialUser
                                                    ? 'Contact for Replacement'
                                                    : 'Request a replacement'
                                                  : isTrialUser
                                                    ? 'Contact to cancel'
                                                    : 'Cancel session'
                                                : null}
                                            </div>
                                          )}
                                      </div>
                                    </ClickDetect>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            {this.props.is_community_class_proposal_pending && (
              <div className="w-full bg-mytBgTransfer p-4 rounded-lg flex justify-between mt-10px items-center">
                <div className="float-left lg:text-13.5px zm:text-12.5px zm:leading-15px lg:leading-16px text-mytTextTransfer leading-snug font-medium">
                  Class is not live yet. Review pending.
                </div>
              </div>
            )}
            {this.props.item.replacement_request_status === 'ACTIVE' ? (
              <div>
                <div className="w-full bg-mytBgTransfer p-4 rounded-lg flex zm:justify-end md:justify-between mt-10px md:items-center zm:flex-row-reverse md:flex-row">
                  {/* middle section */}
                  <div className="flex float-left lg:text-13.5px zm:text-12.5px zm:leading-15px lg:leading-16px text-mytTextTransfer leading-snug font-medium zm:flex-col md:flex-row zm:ml-5px md:ml-0">
                    {this.state.days > 0 || this.state.hours > 0 || this.state.minutes > 0 ? (
                      <div>
                        Finding a replacement in next{' '}
                        {this.state.days > 0
                          ? `${this.state.days} ${this.state.days > 1 ? 'days' : 'day'} `
                          : null}
                        {this.state.hours} hours {this.state.minutes} mins
                      </div>
                    ) : (
                      <div>
                        Time limit to accept replacement elapsed, the class will be cancelled
                        shortly
                      </div>
                    )}
                    {/* withdraw link */}
                    <div
                      className="zm:ml-0 md:ml-5px cursor-pointer underline zm:mt-5px md:mt-0"
                      data-div_session_uuid={this.props.item.session_uuid}
                      onClick={this.withdraw_session_replacement}
                    >
                      Withdraw request
                    </div>
                  </div>
                  <div className="float-right">
                    <RefreshIcon />
                  </div>
                </div>
              </div>
            ) : null}
            {this.state.consultCallActive && session_details.session_type === 'VIDEO_CONSULT' ? (
              <div className="w-full bg-mytBgTransfer px-20px py-12px rounded-lg flex justify-between mt-10px items-center">
                <div className="float-left lg:text-13.5px zm:text-12.5px zm:leading-15px lg:leading-16px text-mytTextTransfer leading-snug font-medium">
                  <div
                    className="flex items-center"
                    onClick={() => {
                      this.props.set_home_variable('goToActiveCall', true);
                    }}
                  >
                    <span className=" flex h-6.5 w-6.5 items-center justify-center bg-consultCallRed rounded-full">
                      <span className="animate-ping h-6.5 w-6.5 bg-consultCallRed rounded-full" />
                      <img className="h-5 absolute" alt="icon" src={phone_white} />
                    </span>
                    <p className="text-mytTextTransfer ml-3 text-inter lg:text-13.5px zm:text-12.5px zm:leading-15px lg:leading-16px leading-snug font-medium">
                      Call in progress
                    </p>
                    <div className="ml-5px">
                      <svg
                        width="7"
                        height="11"
                        viewBox="0 0 7 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.40625 9.98438L5.70625 5.66162L1.40625 1.33887"
                          stroke="#7D4AC3"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {this.props.item?.status !== 'CANCELLED' &&
            this.props.item?.replacement_request_status === 'FULFILLED' &&
            this.props.teacher_details &&
            this.props.item?.teacher_uuid === this.props.teacher_details.uuid &&
            this.props.item?.original_teacher_uuid !== this.props.teacher_details.uuid &&
            this.props.item?.is_teacher_changed === 1 ? (
              <div className="w-full bg-mytBgTransfer p-4 rounded-lg flex justify-between mt-10px items-center">
                <div className="float-left lg:text-13.5px zm:text-12.5px zm:leading-15px lg:leading-16px text-mytTextTransfer leading-snug font-medium">
                  Session transferred from{' '}
                  <span className="font-semibold">{this.props.item?.original_teacher_name}</span>
                </div>
                <div className="float-right">
                  <RefreshIcon />{' '}
                </div>
              </div>
            ) : null}
            {this.props.item?.replacement_request_status === 'FULFILLED' &&
            this.props.teacher_details &&
            this.props.item?.teacher_uuid !== this.props.teacher_details.uuid ? (
              <div className="bg-mytBgTransfer lg:py-12px px-20px zm:pt-18px zm:pb-17px zm:mt-10px lg:mt-18px rounded-lg flex justify-between items-center md:items-center">
                <div className="float-left lg:text-13.5px zm:text-12.5px zm:leading-15px lg:leading-16px text-mytTextTransfer leading-snug font-medium">
                  Session transferred to{' '}
                  <span className="font-semibold">{this.props.item?.current_teacher_name}</span>
                </div>
                <div className="float-right ml-5px">
                  <RefreshIcon />{' '}
                </div>
              </div>
            ) : null}
            {this.props.item?.replacement_request_status === 'FULFILLED' &&
            this.props.item?.status === 'CANCELLED' ? (
              <div className="bg-mytBgCancelled p-4 rounded-lg flex md:flex-row zm:flex-col justify-start md:items-center zm:mt-10px lg:mt-18px">
                <div className="lg:text-13.5px zm:text-12.5px zm:leading-15px lg:leading-16px text-primary leading-snug font-medium">
                  {this.props.item?.replacement_request_action === 'SESSION_CANCELLED_BY_TEACHER'
                    ? 'Session cancelled by teacher'
                    : this.props.item?.replacement_request_action === 'SESSION_CANCELLED_BY_STUDENT'
                      ? 'Session cancelled by student'
                      : this.props.item?.replacement_request_action ===
                          'SESSION_CANCELLED_BY_TIMEOUT'
                        ? 'No replacement found, session cancelled'
                        : ''}
                </div>
              </div>
            ) : null}
          </div>
          {this.state.show_pick_date ? (
            <ChangeSessionDateAndTime
              cancel_pick_date={this.cancel_pick_date}
              session_details={this.props.item}
            />
          ) : null}
          {this.state.showBannerChangeTime ? (
            <div className="up-banner">You've successfully changed the session time!</div>
          ) : null}
        </div>

        {!this.props.isLast && (
          <div className="dashboardContainerWidth">
            <div className="ml-5 mt-2.5 mb-2.5 bg-gray-200 rounded-50px w-1 zm:h-50px md:h-60px" />
          </div>
        )}
        {this.state.show_participant_list &&
          this.props.selected_dashboard_session_uuid === this.props.item.session_uuid && (
            <div className="fixed h-screen w-screen zm:bg-mytHeaderBg inset-0 z-70 flex items-center justify-center">
              <div className="zm:rounded-br-none zm:rounded-bl-none zm:rounded-2xl  zm:w-full sm:w-none sm:w-492px h-710px z-50 bg-white sm:rounded-lg sm:py-40px zm:absolute bottom-0 sm:relative zm:pb-10px zm:pt-40px">
                <div
                  className="flex justify-end cursor-pointer absolute top-1 right-1"
                  onClick={this.closeViewParticiPop}
                >
                  <CloseButtonIcon />
                </div>
                {this.state.show_ban_popup ? (
                  <>
                    {this.state.banParticipant
                      ? this.render_banned_participant()
                      : this.render_ban_alert()}
                  </>
                ) : (
                  <div className="ps-v-partici-in">
                    {/* Popup header STARTS */}
                    <div className="sm:px-30px zm:px-30px pb-17px sm:text-19px font-semibold leading-22px font-inter zm:text-18px">
                      {
                        this.props.get_session_participants_response.session_details
                          .participants_string
                      }
                    </div>
                    {/* Popup header ENDS */}

                    {/* Popup participant level(s) STARTS */}

                    <div className="sm:px-30px zm:px-30px">
                      <ol className="list-reset rounded flex text-sm text-mytgrey-lite font-normal">
                        {this.props.get_session_participants_response.session_details
                          ?.participants_type &&
                        this.props.get_session_participants_response.session_details
                          ?.participants_type.length !== 0
                          ? this.props.get_session_participants_response.session_details?.participants_type.map(
                              (type, index) => (
                                <>
                                  <li className={index === 0 ? 'pr-2' : 'px-2'}>
                                    {type.count} {capitalizeFirstLetter(type.label)}
                                  </li>
                                  {this.props.get_session_participants_response.session_details
                                    .participants_type.length !==
                                  index + 1 ? (
                                    <li>/</li>
                                  ) : null}
                                </>
                              )
                            )
                          : null}
                      </ol>
                    </div>
                    {/* Popup participant level(s) ENDS */}

                    {/* Popup tabs STARTS */}
                    <div className="bg-white border-b border-primary p-0 md:mt-40px zm:mt-20px">
                      <nav
                        className="flex justify-end flex-row-reverse"
                        onClick={this.handleParticipantNav}
                      >
                        {this.props.get_session_participants_response.session_details
                          .total_participants.length !== 0 &&
                          this.props.get_session_participants_response.session_details.total_participants
                            .filter((data) => data.label.toLowerCase() !== 'all')
                            .map((data, index) => (
                              <button
                                id={index}
                                className={
                                  data?.label === 'ALL'
                                    ? ''
                                    : this.state.participantNavStep === data?.label.toLowerCase()
                                      ? 'text-16px font-normal leading-22px py-2 px-6 block hover:text-mytorange-hover focus:outline-none text-mytorange-default border-b-2 border-mytorange-default uppercase'
                                      : 'text-16px font-normal leading-22px text-mytgrey-subText py-2 px-6 block hover:text-mytorange-default focus:outline-none uppercase'
                                }
                                name={data?.label.toLowerCase()}
                              >
                                {data?.label === 'ALL' ? (
                                  ''
                                ) : (
                                  <>
                                    {data?.label} {'  '} ({data?.count})
                                  </>
                                )}
                              </button>
                            ))}
                      </nav>
                    </div>

                    <div className="h-72 overflow-y-auto overflow-x-hidden z-50">
                      {this.props.selected_dashboard_session_uuid === this.props.item.session_uuid
                        ? this.render_tab_data()
                        : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        {this.state.isJoinMeetingPopupOpen && (
          <JoinMeetingCheckList
            onClick={this.openMeeting}
            div_session_uuid={this.props.item.session_uuid}
            onClose={() => this.setState({ isJoinMeetingPopupOpen: false })}
          />
        )}
        {this.state.isConciergeNotesPopupOpen && (
          <ConciergeNotesPopup
            data={this.state.studentDetailsdata}
            onClick={this.openMeeting}
            div_session_uuid={this.props.item.session_uuid}
            onClose={() => this.setState({ isConciergeNotesPopupOpen: false })}
            session_attending={this.props.item?.session_attending}
          />
        )}
        {this.state.showHelpfulQuestionsPopup ? (
          <Questions onCloseRequest={() => this.setState({ showHelpfulQuestionsPopup: false })} />
        ) : null}
        {this.state.show_cancel_confirm ? this.render_confirm_cancel() : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  zoom_signature: state.zoom.zoom_signature,
  zoom_signature_status: state.zoom.zoom_signature_status,
  config_values: state.home.config_values,
  join_url: state.zoom.join_url,
  session_update_slots: state.dashboard.session_update_slots,
  create_roadmap_status: state.home.create_roadmap_status,
  get_session_participants_status: state.home.get_session_participants_status,
  get_session_participants_response: state.home.get_session_participants_response,
  teacher_details: state.profile.teacher_details,
  request_replacement_status: state.home.request_replacement_status,
  makeCall: state.home.makeCall,
  consult_call_active: state.home.consult_call_active,
  impersonate_myt_user_teacher_consultancy_response:
    state.home.impersonate_myt_user_teacher_consultancy_response,
  impersonate_myt_user_teacher_consultancy_response_status:
    state.home.impersonate_myt_user_teacher_consultancy_response_status,
  chat_ready: state.home.chat_ready,
  scheduleSessionDataInfo: state.dashboardv1.scheduleSessionDataInfo,
  cancel_session_status: state.dashboardv1.cancel_session_status,
  selected_dashboard_session_uuid: state.home.selected_dashboard_session_uuid
});

const mapDispatchToProps = (dispatch) => ({
  cancel_session: (payload) => {
    dispatch(actions.cancel_session(payload));
  },
  refresh_sessions: () => {
    dispatch(actions.load_my_sessions());
  },
  get_zoom_token: (payload) => {
    dispatch(actions.get_zoom_signature(payload));
  },
  get_session_update_slots: (payload) => {
    dispatch(actions.get_session_update_slots(payload));
  },
  request_replacement: (payload) => {
    dispatch(actions.request_replacement(payload));
  },
  join_session: (payload) => {
    dispatch(actions.join_session(payload));
  },
  session_log_start: (payload) => {
    dispatch(actions.session_log_start(payload));
  },
  set_zoom_variable: (key, value) => {
    dispatch(actions.set_zoom_variable(key, value));
  },
  set_home_variable: (key, payload) => {
    dispatch(actions.set_home_variable(key, payload));
  },
  load_short_dashboard_sessions: () => {
    dispatch(actions.load_short_dashboard_sessions());
  },
  create_roadmap: (payload) => {
    dispatch(actions.create_roadmap(payload));
  },
  get_session_participants: (payload) => {
    dispatch(actions.get_session_participants(payload));
  },
  withdrawReplacementRequest: (payload) => {
    dispatch(actions.withdrawReplacementRequest(payload));
  },
  impersonate_myt_user_teacher_consultancy: (key, payload) => {
    dispatch(actions.impersonate_myt_user_teacher_consultancy(key, payload));
  }
});

const SingleRenderedSessionWithRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(SingleRenderedSession);
const SingleRenderedSessionWithRouter = withRouter(SingleRenderedSessionWithRedux);

export default SingleRenderedSessionWithRouter;
