import React, { Component } from 'react';
import { connect } from 'react-redux';

import Spinner from '../../util_components/spinner';
import CustomInput from '../../util_components/custom_input';
import CustomButton from '../../util_components/custom_button';

import * as selectors from '../../../redux/selectors';
import * as actions from '../../../redux/action_creators';

import './welcome.css';

class SetPassword extends Component {
  state = {
    pwd: '',
    name: '',
    pwd_error: false,
    error_msg: ''
  };

  componentDidMount() {
    const urlString = window.location.href;
    const url = new URL(urlString);
    // TODO: migrate post ses-notification repo is migrated
    // try {
    //   if (
    //     !getCookie("cms_user_confirmation") ||
    //     getCookie("cms_user_confirmation") !== "true"
    //   ) {
    //     const aws_confirmation_uuid = url.searchParams.get(
    //       "aws_confirmation_uuid"
    //     );
    //     const aws_response_url = `${urls.email_primary_response}?id=${aws_confirmation_uuid}`;
    //     get_api(aws_response_url);
    //   }
    // } catch {}
    const token = url.searchParams.get('verification_token');
    this.props.verify_email_token(token);
  }

  componentWillUnmount() {
    this.props.set_home_variable('reset_password_status', 'none');
    this.props.set_welcome_variable(
      'verification_error_message',
      'Oops something happend please try again'
    );
  }

  handleChange = (e, key) => {
    this.setState({
      ...this.state,
      [key]: e.target.value,
      pwd_error: false,
      redirect_home: false
    });
  };

  onSubmit = () => {
    // this.state.name === "" ||
    if (this.state.pwd === '') {
      this.setState({
        pwd_error: true,
        error_msg: '* All fields are compulsory'
      });
    } else if (this.state.pwd.length < 6) {
      this.setState({
        pwd_error: true,
        error_msg: 'The password should contain minimum 6 characters'
      });
    } else if (this.state.pwd.indexOf(' ') > -1) {
      this.setState({
        pwd_error: true,
        error_msg: 'No spaces are allowed in the password'
      });
    } else if (!(/[a-zA-Z]/.test(this.state.pwd) && /[0-9]/.test(this.state.pwd))) {
      this.setState({
        pwd_error: true,
        error_msg: 'Password should contain atleast 1 alphabet and 1 number'
      });
    } else {
      this.props.set_password({
        name: this.props.verification_name,
        password: this.state.pwd
      });
    }
  };

  redirect_home = () => {
    this.setState({ redirect_home: true });
  };

  render() {
    if (this.props.verification_status === 'inprogress') {
      return (
        <div className="welcome-error">
          <img
            className="myt-logo"
            src="https://images.myyogateacher.com/MYT_Logo.svg"
            alt="Logo"
            width="256"
            height="28"
            style={{ width: '256px', height: '28px' }}
          />
          <Spinner type="dot" />
        </div>
      );
    }
    if (this.props.verification_status === 'fail') {
      return (
        <div className="welcome-error">
          <img
            className="myt-logo"
            src="https://images.myyogateacher.com/MYT_Logo.svg"
            alt="Logo"
            width="256"
            height="28"
            style={{ width: '256px', height: '28px' }}
          />
          {this.props.verification_error_message}
        </div>
      );
    }
    if (this.props.reset_password_status === 'success') {
      return (
        <div className="set-password-container set-pwd-confirm">
          <img
            className="myt-logo sm:block zm:hidden"
            src="https://images.myyogateacher.com/MYT_Logo.svg"
            alt="Logo"
            width="256"
            height="28"
            style={{ width: '256px', height: '28px' }}
          />
          <img
            src="https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/f_auto/v1642535357/website/lp/mobileHeaderIcon.png"
            alt="mobile-logo"
            className="sm:hidden zm:block"
            width="36"
            height="22"
            style={{ width: '50px', height: '34px' }}
          />
          <div className="set-password-text set-password-confirm-text">
            <h3>Great, your account is created!</h3>Click on continue button to complete your
            profile and schedule your first session. <br />
          </div>
          <div className="set-password-submit">
            <CustomButton label="Continue" onClick={() => this.redirect_home()} />
          </div>
        </div>
      );
    }
    return (
      <div className="set-password-container">
        <img
          className="myt-logo sm:block zm:hidden"
          src="https://images.myyogateacher.com/MYT_Logo.svg"
          alt="Logo"
          width="256"
          height="28"
          style={{ width: '256px', height: '28px' }}
        />
        <div className="set-password-text">Your email is verified</div>
        <div className="set-password-text1">Enter a new password to get started.</div>

        {/* <div className="set-input-container">
          <CustomInput
            label="*Name:"
            type="text"
            width={`300px`}
            autoComplete="off"
            handleChange={e => this.handleChange(e, "name")}
          />
        </div> */}
        <div className="set-input-container">
          <CustomInput
            label="*Password:"
            type="password"
            width="300px"
            autoComplete="off"
            handleChange={(e) => this.handleChange(e, 'pwd')}
          />
        </div>
        <div className="set-password-submit">
          <CustomButton label="Submit" onClick={this.onSubmit} />
        </div>
        {this.state.pwd_error ? (
          <div className="set-password-error">{this.state.error_msg}</div>
        ) : null}
        {this.props.password_loading ? (
          <div className="set-password-spinner">
            <Spinner circleRadius={50} />
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
    password_loading: selectors.get_home_password_loading(state),
    reset_password_status: selectors.get_home_reset_password_status(state),
    verification_status: selectors.get_welcome_verifiation_status(state),
    verification_error_message: selectors.get_welcome_verification_error_message(state),
    verification_name: state.home.verification_name
  });

const mapDispatchToProps = (dispatch) => ({
    set_password: (payload) => {
      dispatch(actions.set_password(payload));
    },
    set_home_variable: (key, payload) => {
      dispatch(actions.set_home_variable(key, payload));
    },
    verify_email_token: (payload) => {
      dispatch(actions.verify_email_token(payload));
    },
    set_welcome_variable: (key, payload) => {
      dispatch(actions.set_welcome_variable(key, payload));
    }
  });

const SetPasswordWithRedux = connect(mapStateToProps, mapDispatchToProps)(SetPassword);
export default SetPasswordWithRedux;
