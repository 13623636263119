import React from 'react';
import './page_not_found.css';

const page_not_found = () => (
  <div className="pnp">
    <div className="pnp-header">
      <img
        src="https://images.myyogateacher.com/MYT_Logo.svg"
        alt="Logo"
        className="sm:block zm:hidden"
        style={{ width: '256px', height: '28px', marginLeft: '40px' }}
      />
      <img
        src="https://cloudimages.myyogateacher.com/dgerdfai4/image/upload/f_auto/v1642535357/website/lp/mobileHeaderIcon.png"
        alt="mobile-logo"
        className="sm:hidden zm:block"
        width="36"
        height="22"
        style={{ width: '50px', height: '34px' }}
      />
    </div>
    <div className="pnp-container">
      <img src="https://images.myyogateacher.com/website/page_not_found.png" alt="page not found" />
      <div className="pnp-content">
        <p>
          Well, this is unexpected.
          <br />
          Page not found
        </p>
        <a href="/">
          <div className="pnp-hyperlink">HOME</div>
        </a>
        <div className="pnp-content-links">
          <a
            href="https://www.facebook.com/myyogateacher.inc"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="https://images.myyogateacher.com/website/Logo - Facebook.png" alt="fb" />
          </a>
          <a href="https://twitter.com/myyogateacher" target="_blank" rel="noopener noreferrer">
            <img src="https://images.myyogateacher.com/website/Logo - Twitter.png" alt="fb" />
          </a>
          <a
            href="https://www.instagram.com/myyogateacher"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="https://images.myyogateacher.com/website/Logo - Instagram.png" alt="fb" />
          </a>
        </div>
      </div>
    </div>
  </div>
);

export default page_not_found;
